'use client';

import 'client-only';
import { useSearchParams } from 'next/navigation';
import useSWRMutation from 'swr/mutation';
import { searchParamsToObject } from '../../utils/search-params-to-object';
type Options = {
  redirectTo?: string;
};
export const usePoleEmploi = (options: Options = {}) => {
  const searchParams = useSearchParams();
  return useSWRMutation('pole-emploi-connect', async () => {
    const result = await fetch('/api/pole-emploi/request', {
      method: 'POST',
      body: JSON.stringify({
        queryParams: {
          ...(searchParamsToObject(searchParams) ?? {}),
          ...options
        }
      })
    });
    const {
      url
    } = (await result.json()) as {
      url: string;
    };
    window.location.href = url;
    return true;
  });
};